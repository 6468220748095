import React, { FC } from 'react';
import styled from 'styled-components';
import CardContainer from './CardContainer';
import TickIcon from 'assets/icons/tick-circle.svg';

import { Text } from 'components';
import { smMobile, tablet } from 'styles/breakpoints';
import { ImageWithContainer } from '..';

interface ListProps {
  title: string;
  list: string[];
  bgColor?: string;
  isNumberedList?: boolean;
  padding?: string;
}

const Container = styled.div<{ bgColor?: string; padding: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${({ padding }) => padding};
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 48rem;

  display: flex;
  flex-direction: row;
  @media ${tablet} {
    flex-direction: column;
    max-width: 21.4375rem;
  }
  @media ${smMobile} {
    max-width: 18rem;
  }
`;

const Title = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 0.25rem;
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.75rem;
`;

const Item = styled(Text)`
  color: #28293d;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.011rem;
  margin-left: 1rem;
  flex: 1;
  span {
    color: #38724f;
    font-weight: 700;
  }
`;

const CardContainerStyled = styled(CardContainer)`
  padding: 3rem;
  @media ${tablet} {
    padding: 2rem 1rem 1.5rem 1rem;
  }
`;

const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #38724f;
`;

const Number = styled(Text)`
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const ListItem = ({
  item,
  isNumberedList,
  number,
}: {
  item: string;
  isNumberedList: boolean;
  number: number;
}) => (
  <ListItemContainer>
    {isNumberedList ? (
      <Circle>
        <Number>{number}</Number>
      </Circle>
    ) : (
      <TickIcon width={24} height={24} fill="#38724F" />
    )}
    <Item dangerouslySetInnerHTML={{ __html: item }} />
  </ListItemContainer>
);

const List: FC<ListProps> = ({
  title,
  list,
  bgColor,
  isNumberedList = false,
  padding = '0',
  image,
  ...props
}) => {
  const renderListItem = (item: string, index: number) => (
    <ListItem
      item={item}
      key={index}
      isNumberedList={isNumberedList}
      number={index + 1}
    />
  );
  return (
    <Container bgColor={bgColor} padding={padding} {...props}>
      <ContentContainer>
        <CardContainerStyled>
          <Title>{title}</Title>
          {list.map(renderListItem)}
        </CardContainerStyled>
      </ContentContainer>
    </Container>
  );
};

export default List;
