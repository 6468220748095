import React, { FC } from 'react';
import styled from 'styled-components';

interface CardContainerProps {
  bgColor?: string;
}

const Container = styled.div<{ bgColor?: string; hideCardBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(28, 28, 40, 0.6);
  border-radius: 1rem;
  width: 100%;
  margin-bottom: 0.5rem;
  position: relative;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#ffffff')};
  border-width: ${({ hideCardBorder }) => (hideCardBorder ? '0px' : '1px')};
`;

const CardContainer: FC<CardContainerProps> = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
);

export default CardContainer;
