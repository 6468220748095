import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import { mobile, useQuery } from 'styles/breakpoints';

interface HeadlineProps {
  bodyText: string;
  bgColor?: string;
  textColor?: string;
  padding?: string;
  fontSize?: string;
}

const Container = styled.div<{ bgColor?: string; padding: string }>`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.colors.darkPurple};
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => padding};
  @media ${mobile} {
  }
`;

const BodyText = styled(Text)<{ textColor?: string; fontSize: string }>`
  color: ${props => (props.textColor ? props.textColor : 'dark100')};
  font-size: 1rem;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  font-size: ${({ fontSize }) => fontSize};
`;

const Headline: FC<HeadlineProps> = ({
  bodyText,
  bgColor = 'transparent',
  textColor,
  padding = '0',
  fontSize = '1.25rem',
}) => {
  const { isMobile } = useQuery();

  return (
    <Container bgColor={bgColor} padding={padding}>
      <BodyText
        type={isMobile ? 'buttonTextM' : 'h3500'}
        textColor={textColor}
        dangerouslySetInnerHTML={{ __html: bodyText }}
        fontSize={fontSize}
      />
    </Container>
  );
};

export default React.memo(Headline);
