import React, { FC } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Headline from '../Headline';
import Label from '../Label';
import Button from '../Button';
import CancelButton from '../CancelButton';
import { ImageWithContainer } from '../..';
import List from '../List';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import { DynamicImage } from 'components';

interface AppSubscriptionProps {}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${mobile} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;
const CancelButtonStyled = styled(CancelButton)`
  margin: 0.75rem 0 3rem 0;
`;

const ListStyled = styled(List)`
  padding-bottom: 3rem;
  @media ${tablet} {
    padding-bottom: 1rem;
  }
`;

const TopTextContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadlineAlt = styled.p`
  color: #1c1c28;
  text-align: center;
  font-family: Open Sans;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 2.8rem */
  margin-bottom: 1rem;
  max-width: 48rem;
  @media ${tablet} {
    max-width: 21.4375rem;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
`;

const LabelAlt = styled.p`
  color: #28293d;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.625rem */
  margin-bottom: 2.25rem;
  text-align: center;
  max-width: 48rem;
  @media ${tablet} {
    max-width: 21.4375rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const ImageSectionStyled = styled(DynamicImage)<{ width: string }>`
  width: ${({ width }) => width};
  width: 100%;
  max-width: 74.25rem;
`;

const ImgContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 8rem;
  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const AppSubscription: FC<AppSubscriptionProps> = ({
  bannerOne,
  paymentSteps,
  headlineAlt,
  labelAlt,
  list,
  planSection,
  PlanSectionComponent,
  headline2,
  headline3,
  label,
  planSection2,
  imageSection,

  userHasBoughtPaperbackBook,
}) => {
  const { isTablet } = useQuery();
  return (
    <>
      <BannerOne {...bannerOne} boughtPaperback={userHasBoughtPaperbackBook} />
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <TopTextContainer>
        <HeadlineAlt>{headlineAlt}</HeadlineAlt>
        <LabelAlt>{labelAlt}</LabelAlt>
      </TopTextContainer>
      <PlanSectionComponent {...planSection} />
      <ListStyled {...list} />
      <Headline {...headline2} />
      <Headline {...headline3} />
      <Label {...label} />
      <PlanSectionComponent {...planSection2} />
      <ImgContainer>
        <ImageSectionStyled
          src={isTablet ? imageSection.imageMobile : imageSection.image}
        />
      </ImgContainer>
    </>
  );
};

export default AppSubscription;
